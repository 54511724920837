var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-form-group', {
    staticClass: "my-50",
    attrs: {
      "label-for": "swal-input-otp-mfa"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-start gap-1"
        }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.mfa.inputOtpMfa')) + " "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("(*)")])])];
      },
      proxy: true
    }])
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    staticClass: "form-control-merge",
    attrs: {
      "id": "swal-input-otp-mfa",
      "type": "text",
      "size": "lg",
      "autocomplete": "off",
      "placeholder": _vm.$t('myAccount.mfa.inputOtpMfa')
    },
    model: {
      value: _vm.otpCode,
      callback: function callback($$v) {
        _vm.otpCode = $$v;
      },
      expression: "otpCode"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }