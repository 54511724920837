var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-wrapper auth-v2"
  }, [_c('div', {
    staticClass: "auth-inner m-0",
    class: "".concat(_vm.windowWidth < 768 ? 'd-flex flex-column' : ''),
    staticStyle: {
      "overflow-x": "hidden",
      "width": "100%"
    }
  }, [_vm.windowWidth < 768 ? _c('b-img', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "src": _vm.imgUrl,
      "alt": "Login V2"
    },
    on: {
      "error": function error(val) {
        return _vm.handleErrorImg(val);
      }
    }
  }) : _vm._e(), _c('div', {
    ref: "bannerFullDiv",
    staticClass: "d-flex-center w-100 flex-grow-1",
    style: "".concat(_vm.windowWidth < 768 ? 'background: linear-gradient(180deg, #fff 0%, #9FC9AD 100%);' : !!_vm.imgUrl ? "background: url(".concat(_vm.imgUrl, ") no-repeat center / contain; background-size: 100% 100%; height: 100vh;") : "background: url(".concat(_vm.bannerFull, ") no-repeat center / contain; background-size: 100% 100%; height: 100vh;"))
  }, [_c('b-row', {
    staticClass: "d-flex-center w-100 justify-content-end",
    class: "".concat(_vm.windowWidth < 768 ? 'my-3' : '')
  }, [_c('b-col', {
    staticClass: "d-flex-center",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-card', {
    staticClass: "mb-0 d-flex-center flex-column shadow-lg p-2",
    staticStyle: {
      "width": "320px"
    },
    style: "".concat(_vm.windowWidth < 768 ? '' : ''),
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "w-100 p-50"
  }, [_c('b-link', {
    staticClass: "d-flex-center mb-1"
  }, [_c('b-img', {
    attrs: {
      "src": _vm.appLogoFull,
      "height": "70"
    }
  })], 1), _c('b-card-title', {
    staticClass: "mb-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('login.welcomeTo')) + " " + _vm._s(_vm.appName) + "! 👋 ")]), _c('b-card-text', {
    staticClass: "mb-1 text-info"
  }, [_vm._v(" " + _vm._s(_vm.$t('login.signInText')) + " ")]), _c('validation-observer', {
    ref: "refFormObserver"
  }, [_c('b-form', {
    staticClass: "auth-login-form mt-1"
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('login.Username'),
      "label-for": "login-username"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('login.Username'),
      "vid": "username",
      "rules": "required|min:2"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "login-username",
            "trim": "",
            "autocomplete": "username",
            "state": errors.length > 0 ? false : null,
            "name": "login-username",
            "formatter": _vm.upperCaseFormatter,
            "placeholder": ""
          },
          model: {
            value: _vm.username,
            callback: function callback($$v) {
              _vm.username = $$v;
            },
            expression: "username"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": _vm.$t('login.Password'),
      "label-for": "login-password"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('login.Password'),
      "vid": "password",
      "rules": "required|min:6"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          class: errors.length > 0 ? 'is-invalid' : null
        }, [_c('b-form-input', {
          staticClass: "form-control-merge",
          attrs: {
            "id": "login-password",
            "autocomplete": "current-password",
            "trim": "",
            "state": errors.length > 0 ? false : null,
            "type": _vm.passwordFieldType,
            "name": "login-password",
            "placeholder": ""
          },
          model: {
            value: _vm.password,
            callback: function callback($$v) {
              _vm.password = $$v;
            },
            expression: "password"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.passwordToggleIcon
          },
          on: {
            "click": _vm.togglePasswordVisibility
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "id": "remember-me",
      "button-variant": "warning",
      "name": "checkbox-1"
    },
    model: {
      value: _vm.keepLoggedIn,
      callback: function callback($$v) {
        _vm.keepLoggedIn = $$v;
      },
      expression: "keepLoggedIn"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('login.keepLogin')) + " ")])], 1), _c('b-button', {
    attrs: {
      "type": "submit",
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.login($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('login.LogIn')) + " ")])], 1)], 1), _c('b-card-text', {
    staticClass: "text-center mt-1"
  }, [_c('b-link', {
    attrs: {
      "to": {
        name: 'auth-forgot-password'
      }
    }
  }, [_c('span', {
    staticClass: "text-danger"
  }, [_vm._v(_vm._s(_vm.$t('login.forgotPassword')))])])], 1)], 1)], 1)], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }