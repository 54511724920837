<template lang="">
  <div>
    <b-form-group
      class="my-50"
      label-for="swal-input-otp-mfa"
    >
      <template #label>
        <div class="d-flex justify-content-start gap-1">
          {{ $t('myAccount.mfa.inputOtpMfa') }}
          <span class="text-danger">(*)</span>
        </div>
      </template>
      <b-input-group class="input-group-merge">
        <b-form-input
          id="swal-input-otp-mfa"
          v-model="otpCode"
          class="form-control-merge"
          type="text"
          size="lg"
          autocomplete="off"
          :placeholder="$t('myAccount.mfa.inputOtpMfa')"
        />
      </b-input-group>
    </b-form-group>
  </div>
</template>
<script>
import { BInputGroup, BFormInput, BFormGroup } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

export default {
  components: { BInputGroup, BFormInput, BFormGroup },
  setup() {
    const otpCode = ref()

    return {
      otpCode,
    }
  },
}
</script>
<style lang="">

</style>
